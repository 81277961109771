import React, { useRef, useState } from "react";
import { Button, Text, Heading } from "grommet";
import FileSubmissionForm from "../../containers/forms/FileSubmissionForm";
import { Grommet } from "grommet";
import theme from "../../assets/theme";

const GrommetButton = (props) => {
  const { onClick } = props;
  return (
    <Grommet theme={theme}>
      <Button primary label="Next" onClick={onClick} />
    </Grommet>
  );
};
const Submit = () => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const widgetRef = useRef(null);
  const fileLinks = isReady
    ? widgetRef?.current.getInput().value
    : null;

  return (
    <Grommet theme={theme}>
      {!isReady ? (
        <div className="uploader_container">
          <div className="header_wrapper">
            <Heading as="h4">File Uploads</Heading>
            <Text textAlign="center">
              We accept a variety of file types (i.e .pdf, .dwg, .dgn,
              .zip). Please upload your files below.
            </Text>
          </div>

          <div className="content_wrapper">
            <div className="file_uploader"></div>
          </div>
          <div className="logo_container">
            {uploadedFile ? (
              <GrommetButton onClick={() => setIsReady(true)} />
            ) : null}
          </div>

          <div className="logo_privacy"></div>
        </div>
      ) : (
        <FileSubmissionForm metadata={fileLinks} />
      )}
    </Grommet>
  );
};

export default Submit;
